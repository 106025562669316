import { googleLocationLink, isExternalURL } from '@lib/helpers'
import { Container } from '@styles/global.styled'
import Image from '@global/Image'
import Social from '@global/Social'
import getSocialLinksFromPageData from '@lib/helpers/social'
import { Link } from '@routes'
import * as S from './ServicedBy.styled'
import Button from '@global/Button'

const ServicedBy = ({
  backgroundImage,
  title,
  address1,
  address2,
  telephone,
  fax,
  office,
  directionUrl,
  localOffices,
  officeHours,
  socialLinks,
  path,
}) => (
  <Container>
    <h5>{title}</h5>
    <S.ServicedBy>
      <S.ServicedByInfo>
        <S.Office>
          Barry Plant
          <br /> {office}
        </S.Office>
        <S.OfficeInfo>
          <div>
            {address1 && (
              <span>
                <a
                  target={isExternalURL(path) ? '_blank' : '_self'}
                  href={path}
                >
                  {address1}
                </a>
                <br />
              </span>
            )}
            {address2}
          </div>
          <S.GoogleLink
            href={directionUrl}
            target={isExternalURL(directionUrl) ? '_blank' : '_self'}
          >
            View on Google Maps
          </S.GoogleLink>
          <div>
            {telephone && <a href={`tel:${telephone}`}>T {telephone}</a>}
            {fax && (
              <>
                <span> &nbsp; | &nbsp; </span>
                <a href={`tel:${fax}`}>F {fax}</a>
              </>
            )}
          </div>
        </S.OfficeInfo>
        {localOffices?.length > 0 && (
          <ul>
            <li>Our other local offices include:</li>
            {localOffices.map((item, index) => (
              <li key={index}>
                <a
                  target={
                    isExternalURL(item.link || item.path) ? '_blank' : '_self'
                  }
                  href={item.link || item.path}
                >
                  {item.name || item.title}
                </a>
              </li>
            ))}
          </ul>
        )}
        {socialLinks?.length > 0 && <Social socialLinks={socialLinks} />}

        <S.ServicedByActions>
          {telephone && (
            <Link route={`tel:${telephone}`}>
              <Button color='white'>Call Now</Button>
            </Link>
          )}
          {path && (
            <Link route={path} passHref>
              <Button color='secondary'>Find Out More</Button>
            </Link>
          )}
        </S.ServicedByActions>
      </S.ServicedByInfo>
      <S.ServicedByImage>
        <Image src={backgroundImage} alt={title} />
      </S.ServicedByImage>
    </S.ServicedBy>
  </Container>
)

ServicedBy.defaultProps = {
  address1: '',
  address2: '',
  telephone: '',
  fax: '',
  directionUrl: '',
  email: '',
  localOffices: [],
  officeHours: [],
  socialLinks: [],
}

// eslint-disable-next-line react/display-name
ServicedBy.ApiBlockToProps = (data) => {
  if (!data || !data.value) {
    return null
  }
  const {
    path,
    title,
    address,
    phone,
    fax,
    email,
    results_image_large,
    latitude,
    longitude,
    related_offices,
  } = data.value

  let socialLinks = getSocialLinksFromPageData(data.value)
  return {
    backgroundImage: results_image_large,
    address2: address,
    telephone: phone,
    office: title,
    fax: fax,
    directionUrl: googleLocationLink(data.value),
    email: email,
    localOffices: related_offices,
    officeHours: [],
    socialLinks: socialLinks,
    latitude,
    longitude,
    path: path,
  }
}

export default ServicedBy
